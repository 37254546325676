:root {
  --background-body: #fff;
  --background: #efefef;
  --background-alt: #f7f7f7;
  --selection: #9e9e9e;
  --text-main: #363636;
  --text-bright: #000;
  --text-muted: #70777f;
  --links: #0076d1;
  --focus: rgba(0, 150, 191, 0.67);
  --border: #dbdbdb;
  --code: #000;
  --animation-duration: 0.1s;
  --button-hover: #ddd;
  --scrollbar-thumb: #d5d5d5;
  --scrollbar-thumb-hover: #c4c4c4;
  --form-placeholder: #949494;
  --form-text: #000;
  --variable: #39a33c;
  --highlight: #ff0;
  --select-arrow: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='63' width='117' fill='%23161f27'%3E%3Cpath d='M115 2c-1-2-4-2-5 0L59 53 7 2a4 4 0 00-5 5l54 54 2 2 3-2 54-54c2-1 2-4 0-5z'/%3E%3C/svg%3E");
}
html {
  scrollbar-color: #d5d5d5 #fff;
  scrollbar-color: var(--scrollbar-thumb) var(--background-body);
  scrollbar-width: thin;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    Segoe UI Emoji, Apple Color Emoji, Noto Color Emoji, sans-serif;
  line-height: 1.4;
  max-width: 800px;
  margin: 20px auto;
  padding: 0 10px;
  word-wrap: break-word;
  color: #363636;
  color: var(--text-main);
  background: #fff;
  background: var(--background-body);
  text-rendering: optimizeLegibility;
}
button,
input,
textarea {
  transition: background-color 0.1s linear, border-color 0.1s linear,
    color 0.1s linear, box-shadow 0.1s linear, transform 0.1s ease;
  transition: background-color var(--animation-duration) linear,
    border-color var(--animation-duration) linear,
    color var(--animation-duration) linear,
    box-shadow var(--animation-duration) linear,
    transform var(--animation-duration) ease;
}
h1 {
  font-size: 2.2em;
  margin-top: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 12px;
  margin-top: 24px;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  color: #000;
  color: var(--text-bright);
}
b,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
th {
  font-weight: 600;
}
q:after,
q:before {
  content: none;
}
blockquote,
q {
  border-left: 4px solid rgba(0, 150, 191, 0.67);
  border-left: 4px solid var(--focus);
  margin: 1.5em 0;
  padding: 0.5em 1em;
  font-style: italic;
}
blockquote > footer {
  font-style: normal;
  border: 0;
}
address,
blockquote cite {
  font-style: normal;
}
a[href^="mailto\:"]:before {
  content: "📧 ";
}
a[href^="tel\:"]:before {
  content: "📞 ";
}
a[href^="sms\:"]:before {
  content: "💬 ";
}
mark {
  background-color: #ff0;
  background-color: var(--highlight);
  border-radius: 2px;
  padding: 0 2px;
  color: #000;
}
button,
input[type="button"],
input[type="checkbox"],
input[type="radio"],
input[type="range"],
input[type="submit"],
select {
  cursor: pointer;
}
input:not([type="checkbox"]):not([type="radio"]),
select {
  display: block;
}
button,
input,
select,
textarea {
  color: #000;
  color: var(--form-text);
  background-color: #efefef;
  background-color: var(--background);
  font-family: inherit;
  font-size: inherit;
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 10px;
  border: none;
  border-radius: 6px;
  outline: none;
}
input[type="checkbox"],
input[type="radio"] {
  height: 1em;
  width: 1em;
}
input[type="radio"] {
  border-radius: 100%;
}
input {
  vertical-align: top;
}
label {
  vertical-align: middle;
  margin-bottom: 4px;
  display: inline-block;
}
button,
input:not([type="checkbox"]):not([type="radio"]),
input[type="range"],
select,
textarea {
  -webkit-appearance: none;
}
textarea {
  display: block;
  margin-right: 0;
  box-sizing: border-box;
  resize: vertical;
}
textarea:not([cols]) {
  width: 100%;
}
textarea:not([rows]) {
  min-height: 40px;
  height: 140px;
}
select {
  background: #efefef
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='63' width='117' fill='%23161f27'%3E%3Cpath d='M115 2c-1-2-4-2-5 0L59 53 7 2a4 4 0 00-5 5l54 54 2 2 3-2 54-54c2-1 2-4 0-5z'/%3E%3C/svg%3E")
    calc(100% - 12px) 50%/12px no-repeat;
  background: var(--background) var(--select-arrow) calc(100% - 12px) 50%/12px
    no-repeat;
  padding-right: 35px;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  padding-right: 10px;
  background-image: none;
  overflow-y: auto;
}
button,
input[type="button"],
input[type="submit"] {
  padding-right: 30px;
  padding-left: 30px;
}
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  background: #ddd;
  background: var(--button-hover);
}
button:focus,
input:focus,
select:focus,
textarea:focus {
  box-shadow: 0 0 0 2px rgba(0, 150, 191, 0.67);
  box-shadow: 0 0 0 2px var(--focus);
}
button:active,
input[type="button"]:active,
input[type="checkbox"]:active,
input[type="radio"]:active,
input[type="range"]:active,
input[type="submit"]:active {
  transform: translateY(2px);
}
button:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
::-moz-placeholder {
  color: #949494;
  color: var(--form-placeholder);
}
:-ms-input-placeholder {
  color: #949494;
  color: var(--form-placeholder);
}
::-ms-input-placeholder {
  color: #949494;
  color: var(--form-placeholder);
}
::placeholder {
  color: #949494;
  color: var(--form-placeholder);
}
fieldset {
  border: 1px solid rgba(0, 150, 191, 0.67);
  border: 1px solid var(--focus);
  border-radius: 6px;
  margin: 0 0 12px;
  padding: 10px;
}
legend {
  font-size: 0.9em;
  font-weight: 600;
}
input[type="range"] {
  margin: 10px 0;
  padding: 10px 0;
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 9.5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background: #efefef;
  background: var(--background);
  border-radius: 3px;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #000, 0 0 1px #0d0d0d;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #dbdbdb;
  background: var(--border);
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #efefef;
  background: var(--background);
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 9.5px;
  -moz-transition: 0.2s;
  transition: 0.2s;
  background: #efefef;
  background: var(--background);
  border-radius: 3px;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #dbdbdb;
  background: var(--border);
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 9.5px;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  background: #efefef;
  background: var(--background);
  border: 0.2px solid #010101;
  border-radius: 3px;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
  border: 1px solid #000;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #dbdbdb;
  background: var(--border);
}
input[type="range"]:focus::-ms-fill-lower,
input[type="range"]:focus::-ms-fill-upper {
  background: #efefef;
  background: var(--background);
}
a {
  text-decoration: none;
  color: #0076d1;
  color: var(--links);
}
a:hover {
  text-decoration: underline;
}
code,
samp,
time {
  background: #efefef;
  background: var(--background);
  color: #000;
  color: var(--code);
  padding: 2.5px 5px;
  border-radius: 6px;
  font-size: 1em;
}
pre > code {
  padding: 10px;
  display: block;
  overflow-x: auto;
}
var {
  color: #39a33c;
  color: var(--variable);
  font-style: normal;
  font-family: monospace;
}
kbd {
  background: #efefef;
  background: var(--background);
  border: 1px solid #dbdbdb;
  border: 1px solid var(--border);
  border-radius: 2px;
  color: #363636;
  color: var(--text-main);
  padding: 2px 4px;
}
img,
video {
  max-width: 100%;
  height: auto;
}
hr {
  border: none;
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
}
table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
  table-layout: fixed;
}
table caption,
td,
th {
  text-align: left;
}
td,
th {
  padding: 6px;
  vertical-align: top;
  word-wrap: break-word;
}
thead {
  border-bottom: 1px solid #dbdbdb;
  border-bottom: 1px solid var(--border);
}
tfoot {
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
}
tbody tr:nth-child(2n) {
  background-color: #f7f7f7;
  background-color: var(--background-alt);
}
::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #efefef;
  background: var(--background);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb {
  background: #d5d5d5;
  background: var(--scrollbar-thumb);
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
  background: var(--scrollbar-thumb-hover);
}
::-moz-selection {
  background-color: #9e9e9e;
  background-color: var(--selection);
  color: #000;
  color: var(--text-bright);
}
::selection {
  background-color: #9e9e9e;
  background-color: var(--selection);
  color: #000;
  color: var(--text-bright);
}
details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f7f7f7;
  background-color: var(--background-alt);
  padding: 10px 10px 0;
  margin: 1em 0;
  border-radius: 6px;
  overflow: hidden;
}
details[open] {
  padding: 10px;
}
details > :last-child {
  margin-bottom: 0;
}
details[open] summary {
  margin-bottom: 10px;
}
summary {
  display: list-item;
  background-color: #efefef;
  background-color: var(--background);
  padding: 10px;
  margin: -10px -10px 0;
  cursor: pointer;
  outline: none;
}
summary:focus,
summary:hover {
  text-decoration: underline;
}
details > :not(summary) {
  margin-top: 0;
}
summary::-webkit-details-marker {
  color: #363636;
  color: var(--text-main);
}
footer {
  border-top: 1px solid #dbdbdb;
  border-top: 1px solid var(--border);
  padding-top: 10px;
  color: #70777f;
  color: var(--text-muted);
}
body > footer {
  margin-top: 40px;
}
